// ClosetModel.js

import { Environment, Lightformer, OrbitControls, useScroll } from '@react-three/drei';
import { useFrame, useThree } from '@react-three/fiber';
import { EffectComposer, Outline, Selection } from '@react-three/postprocessing';
import { easing } from 'maath';
import React, { useEffect, useRef, useState } from 'react';
import * as THREE from 'three';
import W3oDefaultCloset from './W3oUserDefaultCloset';
import W3oDefaultClosetMobile from './W3oUserDefaultClosetMobile';

const innerMaterial = new THREE.MeshStandardMaterial({
  transparent: true,
  opacity: 1,
  color: 'black',
  roughness: 0,
  side: THREE.FrontSide,
  blending: THREE.AdditiveBlending,
  polygonOffset: true,
  polygonOffsetFactor: 1,
  envMapIntensity: 2,
});



function Effects() {
  const { size } = useThree();

  return (
    <EffectComposer stencilBuffer disableNormalPass autoClear={false} multisampling={4}>
      <Outline
        visibleEdgeColor="blue"
        hiddenEdgeColor="blue"
        blur
        width={size.width * 0.5}
        edgeStrength={10}
      />
      <Outline
        visibleEdgeColor="blue"
        edgeStrength={100}
        pulseSpeed={0}
        width={100000}
        selectedObjects={[]}
      />
    </EffectComposer>
  );
}

export function Scene({ homeToggle, setOrbitControlsEnabled, isMobile,  lastClickedItemName, setLastClickedItemName,selectedObject, setSelectedObject, hoveredItemDetails, setHoveredItemDetails, isScrolling, cardToggle, isSelected, browseMode, setBrowseMode, hoveredGroup, setHoveredGroup, setCurrentIndex, perfSucks, onPrev, onNext, onClose, currentIndex, orbitControlsEnabled, setIsSelected, mobile3Dcollecion, setMobile3Dcollecion, BLOOM_LAYER, setIsScrolling, setIsMouseVisible, setWhitelistPopup, orbitControlsRef, setIsFooterVisible, isDarkMode, setButtonPopup, buttonPopup, openWhitelistLink, props, scroll }) {
  const { size } = useThree();
  const [scale, setScale] = useState(1);
  const [scale2, setScale2] = useState(1);

  useEffect(() => {
    const updateScale = () => {
      const width = size.width;
      let newScale = 1;

      if (width <= 600) {
        newScale = 0.4;
      } else if (width > 600 && width <= 1200) {
        newScale = 0.71428571;
      }
      setScale2(newScale);
      setScale(newScale);
    };

    updateScale();

    window.addEventListener('resize', updateScale);

    return () => {
      window.removeEventListener('resize', updateScale);
    };
  }, [size?.width]);

  return (
    <>


      {setButtonPopup && (isMobile ? (    <W3oDefaultClosetMobile
          BLOOM_LAYER={BLOOM_LAYER} isMobile={isMobile}  lastClickedItemName={lastClickedItemName} setLastClickedItemName={setLastClickedItemName} isDarkMode={isDarkMode} isScrolling={isScrolling} setIsScrolling={setIsScrolling} scroll={scroll} mobile3Dcollecion={mobile3Dcollecion} setMobile3Dcollecion={setMobile3Dcollecion} setWhitelistPopup={setWhitelistPopup} setIsMouseVisible={setIsMouseVisible} orbitControlsRef={orbitControlsRef} setIsFooterVisible={setIsFooterVisible} openWhitelistLink={openWhitelistLink} setButtonPopup={setButtonPopup} setOrbitControlsEnabled={setOrbitControlsEnabled} buttonPopup={buttonPopup} scale={scale} isSelected={isSelected} orbitControlsEnabled={orbitControlsEnabled} perfSucks={perfSucks} setIsSelected={setIsSelected} props={props} currentIndex={currentIndex} onClose={onClose} onNext={onNext} onPrev={onPrev} setCurrentIndex={setCurrentIndex} browseMode={browseMode} setBrowseMode={setBrowseMode} hoveredGroup={hoveredGroup} setHoveredGroup={setHoveredGroup} cardToggle={cardToggle} hoveredItemDetails={hoveredItemDetails} setHoveredItemDetails={setHoveredItemDetails} selectedObject={selectedObject} setSelectedObject={setSelectedObject} homeToggle={homeToggle}
        />)
      :
      (
        <W3oDefaultCloset
          BLOOM_LAYER={BLOOM_LAYER} isMobile={isMobile}  lastClickedItemName={lastClickedItemName} setLastClickedItemName={setLastClickedItemName} isDarkMode={isDarkMode} isScrolling={isScrolling} setIsScrolling={setIsScrolling} scroll={scroll} mobile3Dcollecion={mobile3Dcollecion} setMobile3Dcollecion={setMobile3Dcollecion} setWhitelistPopup={setWhitelistPopup} setIsMouseVisible={setIsMouseVisible} orbitControlsRef={orbitControlsRef} setIsFooterVisible={setIsFooterVisible} openWhitelistLink={openWhitelistLink} setButtonPopup={setButtonPopup} setOrbitControlsEnabled={setOrbitControlsEnabled} buttonPopup={buttonPopup} scale={scale} isSelected={isSelected} orbitControlsEnabled={orbitControlsEnabled} perfSucks={perfSucks} setIsSelected={setIsSelected} props={props} currentIndex={currentIndex} onClose={onClose} onNext={onNext} onPrev={onPrev} setCurrentIndex={setCurrentIndex} browseMode={browseMode} setBrowseMode={setBrowseMode} hoveredGroup={hoveredGroup} setHoveredGroup={setHoveredGroup} cardToggle={cardToggle} hoveredItemDetails={hoveredItemDetails} setHoveredItemDetails={setHoveredItemDetails} selectedObject={selectedObject} setSelectedObject={setSelectedObject} homeToggle={homeToggle}
        />
      ))}
    </>
  );
}

export function EnvDesktop({ perfSucks, orbitControlsEnabled, BLOOM_LAYER }) {
  const ref = useRef();

  useFrame((state, delta) => {
    if (!perfSucks && !orbitControlsEnabled) {
      easing.damp3(ref.current.rotation, [Math.PI / 2, 0, state.clock.elapsedTime / 50], 0.2, delta);
      easing.damp3(state.camera.position, [Math.sin(state.pointer.x / 400000) * 9, 1.25, Math.cos(state.pointer.x / 4) * 9], 0.5, delta);
      state.camera.lookAt(0, 0, 0);
      state.camera.layers.enable(BLOOM_LAYER);
    }
  });

  return (
    <Environment background={false} files="https://w3omedia.s3.us-east-2.amazonaws.com/w3o+hdr/metro_noord_1k.hdr" path="" preset={null} frames={perfSucks ? 1 : Infinity} resolution={256} blur={0}>
      <Lightformer intensity={12} rotation-x={Math.PI / 2} position={[0, 0, -9]} scale={[10, 10, 1]} />
      <Lightformer intensity={12} rotation-x={Math.PI / 2} position={[0, 0, -9]} scale={[10, 10, 1]} />
      <group rotation={[Math.PI / 2, 1, 0]}>
        {[2, -2, 2, -4, 2, -5, 2, -9].map((x, i) => (
          <Lightformer key={i} intensity={10} rotation={[Math.PI / 4, 0, 0]} position={[x, 4, i * 4]} scale={[4, 1, 1]} />
        ))}
        <Lightformer intensity={0.5} rotation-y={Math.PI / 2} position={[-5, 1, -1]} scale={[50, 2, 1]} />
        <Lightformer intensity={0.5} rotation-y={Math.PI / 2} position={[-5, 1, -1]} scale={[50, 2, 1]} />
        <Lightformer intensity={0.5} rotation-y={-Math.PI / 2} position={[10, 1, 0]} scale={[50, 2, 1]} />
      </group>
      <group ref={ref}>
        <Lightformer intensity={1} form="ring" color="ADD7F2" rotation-y={Math.PI / 2} position={[-5, 2, -1]} scale={[0, 0, 0]} />
      </group>
    </Environment>
  );
}
export function EnvMobile({ perfSucks, orbitControlsEnabled, BLOOM_LAYER }) {
  const { camera } = useThree();

  useFrame(() => {
    if (!perfSucks && !orbitControlsEnabled) {
      // Simplify camera animation for performance
      camera.position.lerp({ x: 0, y: 1.25, z: 9 }, 0.05);
      camera.lookAt(0, 0, 0);
      camera.layers.enable(BLOOM_LAYER);
    }
  });

  return (
    <Environment
      background={false}
      files="https://w3omedia.s3.us-east-2.amazonaws.com/w3o+hdr/metro_noord_1k.hdr"
      frames={perfSucks ? 1 : Infinity}
      resolution={64} // Lower resolution for better performance on mobile
      blur={0.5}      // Slight blur to reduce sharp edges and load
    >
      {/* Simplified lighting setup */}
      <ambientLight intensity={0.5} />
      <directionalLight intensity={0.7} position={[5, 10, 7.5]} />
    </Environment>
  );
}
export default function ClosetModel({isMobile, whitelistPopup, lastClickedItemName, setLastClickedItemName,browseMode, setBrowseMode, setCurrentIndex, onPrev, onNext, onClose, currentIndex, mobile3Dcollecion, isSelected, setIsSelected, setMobile3Dcollecion, setWhitelistPopup, isScrolling, setIsScrolling, isMouseVisible, setIsMouseVisible, orbitControlsRef, setIsFooterVisible, isDarkMode, orbitControlsEnabled, setButtonPopup, buttonPopup, setOrbitControlsEnabled, started, toggleOrbitControls, modelSwitch, setModelSwitch, cardToggle, hoveredGroup, setHoveredGroup, props, hoveredItemDetails, setHoveredItemDetails, homeToggle, selectedObject, setSelectedObject }) {
  const { camera, gl } = useThree();
  const BLOOM_LAYER = 1;
  const [showAbout, setShowAbout] = useState(false);

  const handleCloseAbout = () => setShowAbout(false);
  const ref = useRef();
  const controlsRef = useRef();
  const [perfSucks, degrade] = useState(false);
  const [position, setPosition] = useState([0, -1.5, 0]);
  const scroll = useScroll();

  useEffect(() => {
    function handleResize() {
      const width = window.innerWidth;
      if (width <= 600) {
        setPosition([0, -0.25, 0]);
      } else {
        setPosition([0, -1, 0]);
      }
    }

    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const openWhitelistLink = () => {
    setButtonPopup(true);
  };

  const controls = useThree((s) => s.controls);
  useEffect(() => {
    if (controls) {
      const handler = (event) => {
        setIsMouseVisible(false);
      };

      controls.addEventListener('start', handler);
      return () => controls.removeEventListener('start', handler);
    }
  });

  return (
    <>
      {(orbitControlsEnabled && !browseMode && !isSelected) && (
        <OrbitControls
          makeDefault
          args={[camera, gl.domElement]}
          enableZoom
          enableRotate
          minDistance={5}
          maxDistance={25}
          zoomSpeed={0.6}
          panSpeed={0.5}
          rotateSpeed={0.4}
        />
      )}
 
      <color attach="background" args={['#000']} />
      <group position={position} rotation={[0, -0.75, 0]}>

        <Selection>
        {!isMobile && <Effects />}
          <Scene
            setOrbitControlsEnabled={setOrbitControlsEnabled} isMobile={isMobile}  BLOOM_LAYER={BLOOM_LAYER} lastClickedItemName={lastClickedItemName} setLastClickedItemName={setLastClickedItemName} hoveredItemDetails={hoveredItemDetails} setHoveredItemDetails={setHoveredItemDetails} scroll={scroll} isScrolling={isScrolling} setIsScrolling={setIsScrolling} setWhitelistPopup={setWhitelistPopup} setIsMouseVisible={setIsMouseVisible} orbitControlsRef={orbitControlsRef} orbitControlsEnabled={orbitControlsEnabled} perfSucks={perfSucks} setIsFooterVisible={setIsFooterVisible} openWhitelistLink={openWhitelistLink} setButtonPopup={setButtonPopup} buttonPopup={buttonPopup} isDarkMode={isDarkMode} props={props} isSelected={isSelected} setIsSelected={setIsSelected} mobile3Dcollecion={mobile3Dcollecion} setMobile3Dcollecion={setMobile3Dcollecion} currentIndex={currentIndex} onClose={onClose} onNext={onNext} onPrev={onPrev} setCurrentIndex={setCurrentIndex} browseMode={browseMode} setBrowseMode={setBrowseMode} hoveredGroup={hoveredGroup} setHoveredGroup={setHoveredGroup} cardToggle={cardToggle}  selectedObject={selectedObject} setSelectedObject={setSelectedObject} homeToggle={homeToggle}
          />
        </Selection>
        <mesh scale={[0.95, 1, 0.95]} material={innerMaterial} />
        <mesh material={innerMaterial} />
      </group>
    </>
  );
}
