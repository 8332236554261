import { motion } from 'framer-motion';
import { MdCancel } from 'react-icons/md';
import styled from 'styled-components';

export const ProjectPopup = styled(motion.div)`
  position: fixed;
  top: 50%;
  left: 50%;
 //transform: translate(-50%, -50%);
  background-color: white;
  background: ${({ isDarkMode }) =>
    isDarkMode ? '#fff' : '#333'};

  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: row;
  align-items: center;
  z-index: 9999999999;
  width: 80%;

  @media (max-width: 768px) {
    flex-direction: column;
    width: 90%;
    padding: 15px;
    height: 100vh;
    overflow-x: hidden;
    overflow-y: scroll;
  }
`;

export const ProjectGraphic = styled.img`
  width: 100%;
  max-width: 200px;
  height: auto;
  margin-right: 20px;
  object-fit: cover;
  border-radius: 100%;

  @media (max-width: 768px) {
    margin-right: 0;
    margin-bottom: 15px;
  }
`;

export const ProjectDetails = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;

  @media (max-width: 768px) {
    text-align: center;
  }
`;

export const ProjectTitle = styled.h2`
  margin-bottom: 10px;
  text-align: left;
  display: flex;
  align-items: center;

  @media (max-width: 768px) {
    justify-content: center;
    margin-bottom: 15px;
  }
`;

export const IconWrapper = styled.div`
  display: flex;
  margin-left: 10px;

  svg {
    margin-left: 5px;
    cursor: pointer;
    color: #6c757d;

    &:hover {
      color: #adb5bd;
    }
  }

  @media (max-width: 768px) {
    justify-content: center;
    margin-left: 0;
  }
`;

export const ProjectDescription = styled.p`
  margin: 0;
  text-align: left;

  & strong {
    font-size: 1.1rem;
    font-weight: bold;
  }

  @media (max-width: 768px) {
    text-align: center;
  }
`;

export const ProjectInfoGrid = styled.div`
  display: flex;
  flex-wrap: wrap; /* Allows wrapping for mobile */
  justify-content: space-between;
  width: 100%;
  margin-top: 10px;

  @media (max-width: 768px) {
    display: grid;
    grid-template-columns: repeat(2, 1fr); /* Two columns per row */
    gap: 10px;
    justify-items: center; /* Centers items within each column */
    padding: 10px;
  }

  .dot-divider {
    margin: 0 5px;
    display: flex;
    align-items: center;

    @media (max-width: 768px) {
      display: none;
    }
  }
`;

export const ProjectInfoColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;

  & strong {
    font-size: 1rem;
    font-weight: bolder;
  }

  @media (max-width: 768px) {
    margin-bottom: 10px;
    flex-basis: 48%; /* Two columns per row */
    justify-self: center; /* Centers item in the last row if it's alone */

    &:nth-child(odd) {
      margin-right: 4%;
    }

    &:nth-child(even) {
      margin-right: 0;
    }

    &:only-child {
      grid-column: span 2; /* Takes full width if only one column */
    }
  }
`;


export const ExitIcon = styled(MdCancel)`
  cursor: pointer;
  border-radius: 100%;
  transition: transform 3300ms ease-in-out;
  font-size: 32px;
  transform-origin: center;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    transform: rotate(180deg);
    background-color: ${({ isDarkMode }) => (isDarkMode ? '#fff' : '#000')};
    color: ${({ isDarkMode }) => (isDarkMode ? '#000' : '#fff')};
    filter: drop-shadow(0 0 5px white);
  }
`;

export const PExit = styled(motion.div)`
  position: absolute;
  right: 2.5%;
  top: 2.5%;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
  color: ${({ isDarkMode }) => (isDarkMode ? '#fff' : '#000')};

  &:hover {
    color: ${({ isDarkMode }) => (isDarkMode ? '#000' : '#fff')};
  }

  @media (max-width: 768px) {
    right: 5%;
    top: 5%;
  }
`;

export const ExitIconWrap = styled(motion.div)`
  display: flex;
  justify-content: center;
  align-items: center;
  transform-origin: center;
  cursor: pointer;
`;
