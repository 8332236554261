import { a, useSpring } from "@react-spring/three";
import { Scroll, ScrollControls, useProgress, useScroll } from '@react-three/drei';
import { Canvas } from "@react-three/fiber";
import { AnimatePresence, motion } from 'framer-motion';
import React, { Suspense, useEffect, useRef, useState } from 'react';
import { animated } from 'react-spring';
import styled from 'styled-components';
import Footer from '../../components/Footer/Footer.js';
import Preloader from "../../components/Preloader/CompanyPreloader.js";
import icon2 from '../../images/w3oNameHorzWhite.png';
import { ClosetBottom, ClosetTop, Mouse, MouseBody, Paragraph, WebLogo, WebLogoCont } from "../JoinDao/ModelElements";
import { BlurBackground, MainMouseBody, MouseIcon, ScrollText } from "./CompanyElements";
import { ItemDetailsCardExpanded } from "./Components/3DItemComponents/ItemExpandedCard.js";
import { ItemDetailsCanvasContent, ItemDetailsCardExpandedMobile } from "./Components/3DItemComponents/ItemExpandedMobile.js";
import ClosetModel from './Components/Closet/ClosetModel.js';
import { convertPrice, itemDetailsList, setBlockchain, tempGeneralData } from "./Components/ClosetData/Data.js";
import CustomClosetOverlay from './Components/CustomClosetOverlay/CustomClosetOverlay.js';
import VertFilter from "./Components/NavComponents/Filter/FilterVert.js";
import { SearchNavbar } from "./Components/NavComponents/SearchBar/SearchBar.js";
import { SearchNavbarMobile } from "./Components/NavComponents/SearchBar/SearchBarMobile.js";
import { MainSubNavbar } from "./Components/NavComponents/SubNavbar/SubNavbar.js";
import ListViewMobile from "./Components/PageComponets/List/ListMobile.js";
import { MainListView } from "./Components/PageComponets/List/ListView.js";
import { MainCardView } from "./Components/PageComponets/MainCard/CardView.js";
import { MiniCardView } from "./Components/PageComponets/MiniCard/MiniCardView.js";
import { SplitCardView } from "./Components/PageComponets/SplitView/SplitView.js";
import { SplitCardViewMobile } from "./Components/PageComponets/SplitView/SplitViewMobile.js";
import { ProjectPopUp } from "./Components/ProjectPopup/Project.js";

import { fetchLiveConversionRates, renderIconWithPrice } from "./Components/ClosetData/Data.js";
const fadeInOut = { hidden: { opacity: 0 }, visible: { opacity: 1 } };
const Wrap = styled.div`
  height: 100vh; width: 100vw; position: relative; overflow: hidden;
  -webkit-overflow-scrolling: touch;
  z-index: 1; /* Add this line */
  ::-webkit-scrollbar { width: 3px; }
  ::-webkit-scrollbar-thumb { background-color: darkgrey; border-radius: 3px; }
  ::-webkit-scrollbar-track { background: lightgrey; }
  @media (max-width: 600px) { .model { transform: scale(0.5); } }
  @media (min-width: 601px) and (max-width: 1200px) { .model { transform: scale(0.8); } }
`;

function WebLogoContainer({ onClick, isDarkMode }) {
  return (
    <>
      {isDarkMode ? <WebLogo to='/' src={icon2} alt="Icon" />
        : <WebLogo to='/' src={icon2} alt="Icon" />}
    </>
  );
}

export default function CustomCloset({
  setWhitelistPopup, whitelistPopup, isFooterVisible, setOrbitControlsEnabled, setIsFooterVisible, started, buttonPopup, buttonPopup2, buttonPopup3, setButtonPopup, setButtonPopup2, setButtonPopup3, isDarkMode, orbitControlsEnabled, modelSwitch, setModelSwitch, toggleOrbitControls, daoPopup, setDaoPopup, toggleUtilities }) {

    const [lastClickedItemName, setLastClickedItemName] = useState(null);
  const scroll = useScroll(); 
  const orbitControlsRef = useRef(), sceneContainerRef = useRef(), canvasRef = useRef(), searchBarRef = useRef();
  const [isScrolling, setIsScrolling] = useState(false), [isSelected, setIsSelected] = useState(false), [isBrowseAllMode, setIsBrowseAllMode] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0), [isFullScreen, setIsFullScreen] = useState(false), [viewState, setViewState] = useState(0);
  const [browseMode, setBrowseMode] = useState(false), [hoveredGroup, setHoveredGroup] = useState(null), [isMouseVisible, setIsMouseVisible] = useState(true);
  const [mobile3Dcollecion, setMobile3Dcollecion] = useState(false), [pageSwitch, setPageSwitch] = useState(false), [direction, setDirection] = useState(0);
  const [subscribers, setSubscribers] = useState(123), [diamonds, setDiamonds] = useState(456), [isSubscribed, setIsSubscribed] = useState(false), [windowWidth, setWindowWidth] = useState(window.innerWidth), [showAbout, setShowAbout] = useState(false);;
  const [isDiamondClicked, setIsDiamondClicked] = useState(false), [hoveredItemDetails, setHoveredItemDetails] = useState(null), [selectedObject, setSelectedObject] = useState(null);
  const [isFilterVisible, setIsFilterVisible] = useState(false), [focusedItem, setFocusedItem] = useState(null), [searchTerm, setSearchTerm] = useState('');


  
 // State variables for loading and delay
 const [loading, setLoading] = useState(true);
 const [delayDone, setDelayDone] = useState(false);

 // useProgress hook to get loading progress
 const { progress, active } = useProgress();

  // When loading is complete, start a 2-second delay
  useEffect(() => {
    if (!active && progress === 100) {
      const timer = setTimeout(() => {
        setDelayDone(true);
      }, 2000); // 2-second delay
      return () => clearTimeout(timer);
    }
  }, [active, progress]);

  // Set loading to false when delay is done
  useEffect(() => {
    if (delayDone) {
      setLoading(false);
    }
  }, [delayDone]);

  const w = window.innerWidth, isMobile = w <= 600, maxWidth = 1200;

  const maxRarity = tempGeneralData.length;
    // Calculate minPrice and maxPrice from tempGeneralData
    const safeTempGeneralData = Array.isArray(tempGeneralData) && tempGeneralData.length ? tempGeneralData : [];

    const minPrice = safeTempGeneralData.length
      ? Math.floor(Math.min(...safeTempGeneralData.map(item => parseFloat(item.convertedPriceValue) || 0)))
      : 0;
    
    const maxPrice = safeTempGeneralData.length
      ? safeTempGeneralData[0].maxPrice // Retrieve from the first item
      : 0;

  // Utility to extract unique traits from the collection
  const extractTraitsFromCollection = (data) => {
    const traitsMap = {};
    data.forEach(item => {
      item.traits.forEach(trait => {
        if (!traitsMap[trait.category]) traitsMap[trait.category] = new Set();
        traitsMap[trait.category].add(trait.trait);
      });
    });
    Object.keys(traitsMap).forEach(category => {
      traitsMap[category] = Array.from(traitsMap[category]);
    });
    return traitsMap;
  };
  
  const availableTraits = extractTraitsFromCollection(tempGeneralData); // Utility function for extracting traits

  ////////////////////////////////////////////////////////////////////////
  //                          Advanced Filter                           //
  ////////////////////////////////////////////////////////////////////////


  const [selectedBlockchain, setSelectedBlockchain] = useState({ value: 'eth', label: 'ETH' });
  const [filteredData, setFilteredData] = useState(tempGeneralData);  // Filtered data state
  const [originalData] = useState(tempGeneralData);  // Original data state to reset
  const [conversionRates, setConversionRates] = useState({});
  const [selectedFilter, setSelectedFilter] = useState(null);
  

  useEffect(() => {
    const fetchRates = async () => {
      try {
        const rates = await fetchLiveConversionRates();
        setConversionRates(rates);
      } catch (error) {
        console.error('Error fetching conversion rates:', error);
      }
    };
    fetchRates();
  }, []);




  // Functions missing from the original code are defined here
  const homeToggle = () => { 
    setIsSelected(false); 
    setIsBrowseAllMode(false);  
    setBrowseMode(false);  
    setViewState(0);
    setFilteredData(originalData); // Reset filters to default
    setIsFilterVisible(false); // Hide filter
  };
  
  const browseToggle = () => {
    setBrowseMode(true); 
    setIsSelected(false); 
    setSelectedObject(null); 
    setHoveredGroup(null); 
    setHoveredItemDetails(null); 
    setOrbitControlsEnabled(false);
    if (isMobile) {
      setIsBrowseAllMode(true);
    }
  };
  const cardToggle = () => { setBrowseMode(false); setIsSelected(true); };

  const handleNextItem = () => {   
    setHoveredGroup(null);  
    setHoveredItemDetails(null); 
    setCurrentIndex((prevIndex) => {
      const newIndex = (prevIndex + 1) % itemDetailsList.length;
      setSelectedObject(itemDetailsList[newIndex].group); 
      return newIndex;
    });
  };
  
  const handlePrevItem = () => { 
    setHoveredGroup(null); 
    setHoveredItemDetails(null); 
    setCurrentIndex((prevIndex) => {
      const newIndex = (prevIndex - 1 + itemDetailsList.length) % itemDetailsList.length;
      setSelectedObject(itemDetailsList[newIndex].group); 
      return newIndex;
    });
  };

  const handleBlockchainChange = (newBlockchain) => {
    setSelectedBlockchain(newBlockchain); // Update blockchain state
    setBlockchain(newBlockchain); // Update the global blockchain for the data
  };




  const resetFilters = () => {
    setSelectedBlockchain({ value: 'eth', label: 'ETH' });
    setFilteredData(originalData);  // Reset to original data
    // Reset other filter states if necessary
    // For example, reset minPrice, maxPrice, status, etc.
  };
  


  const handleCloseAbout = () => setShowAbout(false);

  // Springs and animation
// Determine if adjustments for filter should be applied
const isDesktop = !isMobile;
const shouldAdjustForFilter = isDesktop && isFilterVisible && !isSelected && browseMode;

// Base scale and position for closetModelSpring
let closetBaseScale = 1;
let closetBasePosition = [0, 0, 0];

if (isDesktop && (isSelected || browseMode) && viewState === 0) {
  closetBaseScale = windowWidth > maxWidth ? 1 : (windowWidth / maxWidth) * 0.7;
  closetBasePosition = windowWidth > maxWidth ? [0, 0, 0] : [-0.75, 0, 0];
}

// Adjust scale and position if filter is visible
if (shouldAdjustForFilter) {
  closetBaseScale *= 0.65; // Scale down by 25%
  closetBasePosition = [closetBasePosition[0] + 0.65, closetBasePosition[1], closetBasePosition[2]]; // Shift right
}

const closetModelSpring = useSpring({
  scale: closetBaseScale,
  position: closetBasePosition,
  config: { tension: 200, friction: 20 },
});

// Base scale and position for dynamicSpring
let dynamicBaseScale;
let dynamicBasePosition;

if (isMobile) {
  dynamicBaseScale = (isSelected || browseMode)
    ? (viewState !== 0 ? 0 : 0.45)
    : 0.45;
  dynamicBasePosition = (isSelected || browseMode)
    ? (viewState !== 0 ? [0, 0, 0] : [0, 0.2, 0])
    : [0, -0.55, 0];
} else {
  if (viewState !== 0 && (isSelected || browseMode)) {
    dynamicBaseScale = 0.7;
    dynamicBasePosition = [0, 0, 0];
  } else if (isSelected || browseMode) {
    dynamicBaseScale = 0.85;
    dynamicBasePosition = [2, -0.25, 0];
  } else {
    dynamicBaseScale = 1;
    dynamicBasePosition = [0, 0, 0];
  }


}

const dynamicSpring = useSpring({
  scale: dynamicBaseScale,
  position: dynamicBasePosition,
  config: { tension: 200, friction: 20 },
});


  const slideOutToLeft = useSpring({ transform: pageSwitch ? 'translateX(-100%)' : 'translateX(0)', from: { transform: 'translateX(0)' }, config: { tension: 250, friction: 20 } });
  const overlaySpring = useSpring({ opacity: (isSelected || browseMode) ? 0 : 1, config: { tension: 200, friction: 20 } });
  const footerSpring = useSpring({ opacity: isFooterVisible ? 1 : 0, config: { tension: 200, friction: 20 } });
  const canvasSpring = useSpring({ transform: isFooterVisible ? 'translateY(-50px)' : 'translateY(0px)', config: { tension: 200, friction: 20 } });
  const contentSpring = useSpring({ transform: isFooterVisible ? 'translateY(-50px)' : 'translateY(0px)', config: { tension: 200, friction: 20 } });

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    // Check if sceneContainerRef.current exists before using it
    if (sceneContainerRef.current) {
      if (isFooterVisible) {
        sceneContainerRef.current.scrollTo({ behavior: 'smooth' });
      } else {
        sceneContainerRef.current.scrollTo({ behavior: 'smooth' });
      }
    }
  }, [isFooterVisible]);

  const handleBrowseAllClick = () => setIsBrowseAllMode(prevMode => !prevMode);
  const togglePageSwitch = () => { setPageSwitch(prev => !prev); setDirection(pageSwitch ? -1 : 1); };
  const handleFollowClick = () => { setIsSubscribed(!isSubscribed); setSubscribers(isSubscribed ? subscribers - 1 : subscribers + 1); };
  const handleDiamondClick = () => { setIsDiamondClicked(!isDiamondClicked); setDiamonds(isDiamondClicked ? diamonds - 1 : diamonds + 1); };
  const handleItemClick = (itemName) => setFocusedItem(itemName);
  const handleCloseItem = () => setIsSelected(false);
  const toggleFilterVisibility = () => setIsFilterVisible(prev => !prev);
  const handleSearch = (term) => setSearchTerm(term);
  const handleFilterChange = (filter) => setSelectedFilter(filter);
  const [showInfoPopup, setShowInfoPopup] = useState(false);
  const [activeTab, setActiveTab] = useState('about');
  // Get the current item detail
  const currentDetail = itemDetailsList[currentIndex];
  const { scale, position } = useSpring({
    position: showInfoPopup ? [0, 0.15, 2] : [0, 0.45, 5],
    scale: showInfoPopup ? [0.009, 0.009, 0.009] : [0.0215, 0.0215, 0.0215], // Shrink by 80% if info is shown
    config: { tension: 300, friction: 30 },
  });
 // Filtering and Sorting Data
 const filterAndSortData = () => {
  let data = [...filteredData]; // Clone to avoid mutating state

  // Apply search term filtering
  if (searchTerm && searchTerm.trim() !== '') {
    data = data.filter(item =>
      item.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      item.number.toLowerCase().includes(searchTerm.toLowerCase())
    );
  }

  // Apply selected filter sorting
  if (selectedFilter) {
    data = data.slice().sort((a, b) => {
      switch (selectedFilter) {
        case 'Price: Low to High':
          return parseFloat(a.price) - parseFloat(b.price);
        case 'Price: High to Low':
          return parseFloat(b.price) - parseFloat(a.price);
        case 'Recently Listed':
          return b.listedTimeType - a.listedTimeType; // Most recent first
        case 'Oldest Listed':
          return a.listedTimeType - b.listedTimeType; // Oldest first
        case 'Rare to Common':
          return a.rarity - b.rarity;
        case 'Common to Rare':
          return b.rarity - a.rarity;
        case 'Item Number: Low to High':
          return parseInt(a.number.replace(/[^0-9]/g, ''), 10) - parseInt(b.number.replace(/[^0-9]/g, ''), 10);
        case 'Item Number: High to Low':
          return parseInt(b.number.replace(/[^0-9]/g, ''), 10) - parseInt(a.number.replace(/[^0-9]/g, ''), 10);
        default:
          return 0;
      }
    });
  }

  return data;
};


const sortedAndFilteredData = filterAndSortData();








const applyFilters = (filters) => {
  const { blockchain, status, minPrice, maxPrice, minRarity, maxRarity, selectedTraits } = filters;

  // Convert data based on blockchain
  const convertedData = originalData.map(item => {
    const conversionRate = conversionRates[blockchain.toUpperCase()] || 1; // Default to 1 if rate not found
    const convertedPrice = convertPrice(parseFloat(item.price), blockchain, conversionRates); // Implement correctly
    const numericPrice = parseFloat(convertedPrice.split(' ')[0]); // Extract numeric value

    return {
      ...item,
      ethPrice: renderIconWithPrice(convertedPrice, blockchain),
      convertedPrice: numericPrice,
      chain: blockchain,
    };
  });

  // Apply filters on convertedData
  let filtered = convertedData;

  // Filter by status
  if (status !== 'All') {
    filtered = filtered.filter(item => item.status === status);
  }

  // Filter by price range
  filtered = filtered.filter(item => item.convertedPrice >= minPrice && item.convertedPrice <= maxPrice);

  // Filter by rarity
  if (minRarity !== undefined && maxRarity !== undefined) {
    filtered = filtered.filter(item => item.rarity >= minRarity && item.rarity <= maxRarity);
  }

  // Filter by traits
  if (selectedTraits && Object.keys(selectedTraits).length > 0) {
    const selectedTraitsArray = [];
    Object.values(selectedTraits).forEach(traitsArray => {
      traitsArray.forEach(trait => selectedTraitsArray.push(trait.value));
    });

    filtered = filtered
      .map(item => {
        const itemTraits = item.traits.map(trait => trait.attribute);
        const matchingTraitsCount = selectedTraitsArray.reduce((count, trait) => {
          return count + (itemTraits.includes(trait) ? 1 : 0);
        }, 0);
        return {
          ...item,
          matchingTraitsCount,
        };
      })
      .filter(item => item.matchingTraitsCount > 0)
      .sort((a, b) => b.matchingTraitsCount - a.matchingTraitsCount); // Sort by matching traits descending
  }

  // Update filteredData state
  setFilteredData(filtered);
};



const renderView = (data) => {
  if (data.length === 0) {
    return (
      <p style={{ zIndex: 99999999999, position: 'fixed', marginLeft: '10%', marginTop: '17.5%', color: isDarkMode ? 'white' : 'black', textAlign: 'center' }}>
        No results found
      </p>
    );
  }

  switch (viewState) {
    case 1:
      return <MainCardView shouldAdjustForFilter={shouldAdjustForFilter} isFilterVisible={isFilterVisible} isDarkMode={isDarkMode} tempGeneralData={data} />;
    case 2:
      return <MiniCardView shouldAdjustForFilter={shouldAdjustForFilter} isFilterVisible={isFilterVisible} isDarkMode={isDarkMode} tempGeneralData={data} />;
    case 3:
      return isMobile 
        ? <ListViewMobile isFilterVisible={isFilterVisible} isDarkMode={isDarkMode} tempGeneralData={data} />
        : <MainListView shouldAdjustForFilter={shouldAdjustForFilter} isFilterVisible={isFilterVisible} isDarkMode={isDarkMode} tempGeneralData={data} />;
    default:
      return isMobile 
        ? <SplitCardViewMobile isFilterVisible={isFilterVisible} isDarkMode={isDarkMode} tempGeneralData={data} />
        : <SplitCardView shouldAdjustForFilter={shouldAdjustForFilter} isFilterVisible={isFilterVisible} isDarkMode={isDarkMode} tempGeneralData={data} />;
  }
};

  return (

    

    <>


        <>
      {(((viewState === 1) || (viewState === 2) || (viewState === 3)) && (!orbitControlsEnabled) && (!isSelected)  && (browseMode)) && (
        <BlurBackground isDarkMode={isDarkMode}
          style={{ position: 'absolute', opacity: 1, transition: 'opacity 0.5s ease' }}
          isMouseVisible={true}></BlurBackground>
      )}

      {!orbitControlsEnabled && (
        <MainSubNavbar showAbout={showAbout} setShowAbout={setShowAbout} isDarkMode={isDarkMode}
          handleFollowClick={handleFollowClick} handleDiamondClick={handleDiamondClick} isMobile={isMobile}
          subscribers={subscribers} diamonds={diamonds} isSubscribed={isSubscribed} isDiamondClicked={isDiamondClicked} />
      )}

      {!loading && (
  <AnimatePresence>
    <motion.div 
      initial="hidden" 
      animate="visible" 
      exit="hidden" 
      variants={fadeInOut}
      transition={{ duration: 1 }} 
      style={{ zIndex: 99999, position: 'relative', top: '12.5%' }}>
      {!isSelected && !browseMode && !orbitControlsEnabled && (
        <MainMouseBody 
          style={{ 
            position: 'absolute', 
            top: isMobile ? '2.5vh' : '25vh', 
            opacity: isSelected > 0 ? 0 : 1, 
            transition: 'opacity 0.5s ease' 
          }}>
          <ScrollText style={{ marginBottom: '-20px' }}>
            {isMobile ? 'Tap an item or scroll to' : 'Select an item or scroll to'}
          </ScrollText>
          <ScrollText>view the collection!</ScrollText>
          <MouseIcon className="mouse" />
        </MainMouseBody>
      )}
    </motion.div>
  </AnimatePresence>
)}


      {(orbitControlsEnabled && !browseMode && !isSelected) && (
        <>
          <ClosetTop style={{ y: orbitControlsEnabled ? -100 : 100 }} animate={{ y: 0 }} transition={{ duration: 0.5 }} isDarkMode={isDarkMode} />
          <WebLogoCont initial={{ y: orbitControlsEnabled ? -100 : 100, x: '-50%' }} animate={{ y: 0, x: '-50%' }} transition={{ duration: 0.25, delay: 0.5 }} to={'/'} isDarkMode={isDarkMode}>
            <WebLogoContainer isDarkMode={isDarkMode} />
          </WebLogoCont>
          <MouseBody style={{ position: 'absolute', opacity: isMouseVisible > 0 ? 1 : 0, transition: 'opacity 0.5s ease' }}>
            <Paragraph>Click & Drag to Interact!</Paragraph>
            <Mouse className="mouse" />
          </MouseBody>
          <ClosetBottom style={{ y: orbitControlsEnabled ? 100 : -100 }} animate={{ y: 0 }} transition={{ duration: 0.5 }} isDarkMode={isDarkMode} />
        </>
      )}

      <animated.div style={{ ...overlaySpring }}>
        <CustomClosetOverlay mobile3Dcollecion={mobile3Dcollecion} setMobile3Dcollecion={setMobile3Dcollecion} togglePageSwitch={togglePageSwitch}
          pageSwitch={pageSwitch} setPageSwitch={setPageSwitch} setIsMouseVisible={setIsMouseVisible} 
          setOrbitControlsEnabled={setOrbitControlsEnabled} orbitControlsEnabled={orbitControlsEnabled}
          toggleOrbitControls={toggleOrbitControls} toggleUtilities={toggleUtilities} isDarkMode={isDarkMode}
          setButtonPopup={setButtonPopup} setButtonPopup2={setButtonPopup2} setButtonPopup3={setButtonPopup3}
          buttonPopup={buttonPopup} buttonPopup2={buttonPopup2} buttonPopup3={buttonPopup3} setDaoPopup={setDaoPopup}
          daoPopup={daoPopup} started={started} setModelSwitch={setModelSwitch} modelSwitch={modelSwitch} />
      </animated.div>

      <>
        {(isSelected || browseMode) && (
          isMobile ? <SearchNavbarMobile onSearch={handleSearch} setIsBrowseAllMode={setIsBrowseAllMode} ref={searchBarRef} setIsSelected={setIsSelected} homeToggle={homeToggle} browseMode={browseMode} setBrowseMode={setBrowseMode}
            mobile3Dcollecion={mobile3Dcollecion} setMobile3Dcollecion={setMobile3Dcollecion} isBrowseAllMode={isBrowseAllMode} onBrowseAllClick={handleBrowseAllClick}
            onWearablesClick={() => setPageSwitch(true)} onNFTsClick={() => setPageSwitch(false)} isDarkMode={isDarkMode} setViewState={setViewState}
            onFilterChange={handleFilterChange} viewState={viewState} setSelectedObject={setSelectedObject} browseToggle={browseToggle}
            setHoveredGroup={setHoveredGroup} setHoveredItemDetails={setHoveredItemDetails} selectedFilter={selectedFilter} onToggleFilter={toggleFilterVisibility} />
            :
            <SearchNavbar onSearch={handleSearch} setIsBrowseAllMode={setIsBrowseAllMode} ref={searchBarRef} setIsSelected={setIsSelected} homeToggle={homeToggle} browseMode={browseMode} setBrowseMode={setBrowseMode}
              mobile3Dcollecion={mobile3Dcollecion} setMobile3Dcollecion={setMobile3Dcollecion} isBrowseAllMode={isBrowseAllMode} onBrowseAllClick={handleBrowseAllClick}
              onWearablesClick={() => setPageSwitch(true)} onNFTsClick={() => setPageSwitch(false)} isDarkMode={isDarkMode} setViewState={setViewState}
              onFilterChange={handleFilterChange} viewState={viewState} setSelectedObject={setSelectedObject} browseToggle={browseToggle}
              setHoveredGroup={setHoveredGroup} setHoveredItemDetails={setHoveredItemDetails} selectedFilter={selectedFilter} onToggleFilter={toggleFilterVisibility} />
        )}
      </>

      {browseMode && !isSelected && renderView(sortedAndFilteredData)}

      <animated.div ref={sceneContainerRef} style={{ position: 'relative', height: '100vh', width: '100vw', overflow: 'hidden', ...canvasSpring }}>
        <animated.div style={{ position: 'relative', height: '100vh', width: '100vw', overflow: 'hidden', ...contentSpring }}>
          <Suspense fallback={ <Preloader />}>
            <Wrap>
              <Canvas ref={canvasRef} style={{ width: '100%', height: '100%' }} camera={{ position: [20, 0.9, 20], fov: 26 }}>
                <ScrollControls damping={0} pages={0}>
                  <Scroll>
                    <a.group style={slideOutToLeft}>
                      <a.group scale={dynamicSpring.scale} position={dynamicSpring.position}>
                        <a.group scale={closetModelSpring.scale} position={closetModelSpring.position}>
                          <ClosetModel hoveredGroup={hoveredGroup} setHoveredGroup={setHoveredGroup} browseMode={browseMode} setBrowseMode={setBrowseMode} mobile3Dcollecion={mobile3Dcollecion} setMobile3Dcollecion={setMobile3Dcollecion}
                            isScrolling={isScrolling} setIsScrolling={setIsScrolling} onClickItem={handleItemClick} focusedItem={focusedItem} canvasRef={canvasRef} isMouseVisible={isMouseVisible} setIsMouseVisible={setIsMouseVisible}
                            orbitControlsRef={orbitControlsRef} isFooterVisible={isFooterVisible} scroll={scroll} setModelSwitch={setModelSwitch} modelSwitch={modelSwitch} setButtonPopup={setButtonPopup} buttonPopup={buttonPopup}
                            orbitControlsEnabled={orbitControlsEnabled} toggleOrbitControls={toggleOrbitControls} isDarkMode={isDarkMode} setIsFooterVisible={setIsFooterVisible} setWhitelistPopup={setWhitelistPopup}
                            whitelistPopup={whitelistPopup} isSelected={isSelected} setIsSelected={setIsSelected} setCurrentIndex={setCurrentIndex} currentIndex={currentIndex} onClose={handleCloseItem}  setOrbitControlsEnabled={setOrbitControlsEnabled}
                            onNext={handleNextItem} onPrev={handlePrevItem} cardToggle={cardToggle} selectedObject={selectedObject} setSelectedObject={setSelectedObject} hoveredItemDetails={hoveredItemDetails}
                            setHoveredItemDetails={setHoveredItemDetails} homeToggle={homeToggle} lastClickedItemName={lastClickedItemName} setLastClickedItemName={setLastClickedItemName} isMobile={isMobile} />
                        </a.group>
                      </a.group>
                    </a.group>
                  </Scroll>
                </ScrollControls>
                {isSelected && isMobile && (
                  <a.group scale={scale} position={position}>
                    <ItemDetailsCanvasContent
                      hoveredItem={currentDetail.group}
                    />
                  </a.group>
                )}
              </Canvas>
            </Wrap>
          </Suspense>
        </animated.div>
      </animated.div>

        {((isFilterVisible && !isMobile) && !(isSelected && !browseMode) && !(!isSelected && !browseMode)) && (
          <VertFilter isMobile={isMobile} onToggleFilter={toggleFilterVisibility}  originalData={originalData} setFilteredData={setFilteredData} setBlockchain={setSelectedBlockchain}  selectedBlockchain={selectedBlockchain}  maxRarity={maxRarity}  isFilterVisible={isFilterVisible} isDarkMode={isDarkMode} onApplyFilter={applyFilters} onResetFilter={resetFilters} setIsFilterVisible={setIsFilterVisible} tempGeneralData={tempGeneralData} minPrice={minPrice} maxPrice={maxPrice} availableTraits={availableTraits} />
      )}
        {isMobile && (
          <VertFilter isMobile={isMobile} onToggleFilter={toggleFilterVisibility}  originalData={originalData} setFilteredData={setFilteredData} setBlockchain={setSelectedBlockchain}  selectedBlockchain={selectedBlockchain}  maxRarity={maxRarity}  isFilterVisible={isFilterVisible} isDarkMode={isDarkMode} onApplyFilter={applyFilters} onResetFilter={resetFilters} setIsFilterVisible={setIsFilterVisible} tempGeneralData={tempGeneralData} minPrice={minPrice} maxPrice={maxPrice} availableTraits={availableTraits} />
      )}


      {(isSelected && !browseMode) && (
        isMobile ? <ItemDetailsCardExpandedMobile style={{ position: 'absolute', top: 0, left: 0, zIndex: 0 }} isSelected={isSelected} isDarkMode={isDarkMode} isMobile={isMobile} details={itemDetailsList} currentIndex={currentIndex} onClose={handleCloseItem}
          onNext={handleNextItem} onPrev={handlePrevItem} setIsSelected={setIsSelected} hoveredGroup={hoveredGroup} setHoveredGroup={setHoveredGroup} setSelectedObject={setSelectedObject} activeTab={activeTab} setActiveTab={setActiveTab}
          hoveredItemDetails={hoveredItemDetails} setHoveredItemDetails={setHoveredItemDetails} itemDetailsList={itemDetailsList} showInfoPopup={showInfoPopup} setShowInfoPopup={setShowInfoPopup} />
          : <ItemDetailsCardExpanded isSelected={isSelected} isDarkMode={isDarkMode} isMobile={isMobile} details={itemDetailsList} currentIndex={currentIndex} onClose={handleCloseItem}
            onNext={handleNextItem} onPrev={handlePrevItem} setIsSelected={setIsSelected} hoveredGroup={hoveredGroup} setHoveredGroup={setHoveredGroup} setSelectedObject={setSelectedObject}
            hoveredItemDetails={hoveredItemDetails} setHoveredItemDetails={setHoveredItemDetails} itemDetailsList={itemDetailsList} />
      )}

      {isFooterVisible && (
        <animated.div style={{ ...footerSpring }}>
          <Footer setDaoPopup={setDaoPopup} daoPopup={daoPopup} isDarkMode={isDarkMode} setWhitelistPopup={setWhitelistPopup} whitelistPopup={whitelistPopup} />
        </animated.div>
      )}

      {showAbout && (
        <>
          <BlurBackground style={{ position: 'absolute', opacity: 1, transition: 'opacity 0.5s ease', zIndex: 9999999999999 }} isDarkMode={isDarkMode} isMouseVisible={true} />
          <ProjectPopUp handleCloseAbout={handleCloseAbout} placeholderImageUrl="https://source.unsplash.com/random/400x300" />
        </>
      )}
    </>
      
    </>
  );
}